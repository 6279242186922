import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./SecondStage.styled";
import { Button, Loading, TextInput } from "carbon-components-react";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../services/api";
import { Application } from "../../types/Application";
import Student from "../../types/Student";
import CustomModal from "../CustomModal/CustomModal";
import { ApplicationsContext } from "../../context/ApplicationsContext/ApplicationsContext";
import { OptionsContext } from "../../context/OptionsContext/OptionsContext";

type Props = {
  application?: Application | undefined;
  student: Student;
};
type Inputs = {
  id?: number;
  gradeSecondStage: {
    firstGrade?: number;
    secondGrade?: number;
    thirdGrade?: number;
  };
  comment?: string;
};

const SecondStage: FunctionComponent<Props> = ({ application, student }) => {
  const [success, setSuccess] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [errMsg, setErrMsg] = useState("sss");
  const [appli, setAppli] = useState<any>(null);
  useEffect(() => {
    setAppli(application);
  }, [application]);

  const { loadApplications } = React.useContext(ApplicationsContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSuccess(false);
    setLoadingState(true);
    try {
      if (application) {
        await api.put(application["@id"].replace("api/", "") + "/grade", {
          gradeSecondStage: {
            firstGrade:
              data.gradeSecondStage.firstGrade ||
              data.gradeSecondStage.firstGrade === 0
                ? Number(data.gradeSecondStage.firstGrade)
                : null,
            secondGrade: data.gradeSecondStage.secondGrade
              ? Number(data.gradeSecondStage.secondGrade)
              : null,
            thirdGrade: data.gradeSecondStage.thirdGrade
              ? Number(data.gradeSecondStage.thirdGrade)
              : null,
          },
          comment: data.comment ?? null,
        });
      } else if (!appli) {
        await api
          .post("/applications", {
            student: `api/students/${student.id}`,
          })
          .then((r) => {
            return r.data;
          })
          .then((application) => {
            setAppli(application);

            api.put(application["@id"].replace("api/", "") + "/grade", {
              gradeSecondStage: {
                firstGrade: data.gradeSecondStage.firstGrade
                  ? Number(data.gradeSecondStage.firstGrade)
                  : null,
                secondGrade: data.gradeSecondStage.secondGrade
                  ? Number(data.gradeSecondStage.secondGrade)
                  : null,
                thirdGrade: data.gradeSecondStage.thirdGrade
                  ? Number(data.gradeSecondStage.thirdGrade)
                  : null,
              },
              comment: data.comment ?? null,
            });
          });
      }
      setLoadingState(false);
      setSuccess(true);
      loadApplications();
    } catch (err) {
      if (err) {
        setErrMsg("Błąd połączenia");
      }
    }
  };

  return (
    <Styled.SecondStage>
      {loadingState && (
        <CustomModal>
          <Loading />
        </CustomModal>
      )}
      <Styled.Title>Etap II</Styled.Title>
      <Styled.FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Wrapper>
            <TextInput
              {...register("gradeSecondStage.firstGrade", {
                max: {
                  value: 40,
                  message: "Wprowadź liczbę między 0 a 40",
                },
              })}
              id={"test"}
              labelText={"Wpisz liczbę punktów za test"}
              step={0.01}
            />
            <p>
              Aktualnie przyznane punkty:{" "}
              {application?.gradeSecondStage?.firstGrade ??
                "Jeszcze nie przyznano"}
            </p>
          </Styled.Wrapper>
          <Styled.Wrapper>
            <TextInput
              {...register("gradeSecondStage.secondGrade", {
                max: {
                  value: 50,
                  message: "Wprowadź liczbę między 0 a 50",
                },
              })}
              id={"praca"}
              labelText={"Wpisz liczbę punktów za pracę "}
              step={0.01}
            />
            <p>
              Aktualnie przyznane punkty:{" "}
              {application?.gradeSecondStage?.secondGrade ??
                "Jeszcze nie przyznano"}
            </p>
          </Styled.Wrapper>
          <Styled.Wrapper>
            <TextInput
              {...register("gradeSecondStage.thirdGrade", {
                max: {
                  value: 50,
                  message: "Wprowadź liczbę między 0 a 50",
                },
              })}
              id={"ustny"}
              labelText={"Wpisz liczbę punktów za odpowiedź ustną"}
              step={0.01}
            />
            <p>
              Aktualnie przyznane punkty:{" "}
              {application?.gradeSecondStage?.thirdGrade ??
                "Jeszcze nie przyznano"}
            </p>
          </Styled.Wrapper>
          <Styled.Wrapper>
            <TextInput
              {...register("comment", {})}
              id={"comment"}
              labelText={"Komentarz"}
            />
            <p>Komantarz: {application?.comment ?? "Nie wpisano komentarza"}</p>
          </Styled.Wrapper>
          <Button type={"submit"}>Zatwierdź</Button>
        </form>
      </Styled.FormWrapper>
    </Styled.SecondStage>
  );
};

export default SecondStage;
