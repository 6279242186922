import React, { FunctionComponent, useEffect, useState } from "react";
import {
  FileUploaderDropContainer,
  FormItem,
  FileUploaderItem,
  Checkbox,
} from "carbon-components-react";
import api from "../../../../../services/api";
import * as Styled from "./FileUploader.styled";
type Props = {};

const FileUploader: FunctionComponent<Props> = (props) => {
  const [file, setFile] = useState<null | File>();
  const [uploadingStatus, setUploadingStatus] = useState<string>("");
  const [isApplicationAlreadyUploaded, setIsApplicationAlreadyUploaded] =
    useState(false);
  const [currentApplication, setCurrentApplication] = useState<string>("lol");
  const [applications, setApplications] = useState<[]>();
  const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async (file: File) => {
    let fileToUpload = file;
    let formData = new FormData();
    formData.append("file", fileToUpload);
    try {
      await api.post("/files", formData).then((r) => {
        if (isApplicationAlreadyUploaded) {
          api
            .put(currentApplication, {
              file: r.data["@id"],
            })
            .then(() => {
              api.get("/applications").then((r) => {
                setApplications(r.data["hydra:member"][0]);
              });
            });
        } else {
          api
            .post("/applications", {
              file: r.data["@id"],
            })
            .then(() => {
              api.get("/applications").then((r) => {
                setApplications(r.data["hydra:member"][0]);
              });
            });
        }
      });
      setUploadingStatus("complete");
    } catch (err) {
      if (err) {
      }
    }
  };

  const getCurrentApplication = async () => {
    try {
      await api.get("/applications").then((r) => {
        if (r.data["hydra:member"].length > 0) {
          setIsApplicationAlreadyUploaded(true);
          setCurrentApplication(
            r.data["hydra:member"][0]["@id"].replace("/api", "")
          );
        } else {
          setIsApplicationAlreadyUploaded(false);
        }
      });
    } catch (err) {
      if (err) {
      }
    }
  };

  useEffect(() => {
    if (file) {
      setIsCheckboxVisible(true);
      if (isChecked) {
        setIsCheckboxVisible(false);
        setUploadingStatus("uploading");
        uploadFile(file);
      }
    }
  }, [file, isChecked]);

  useEffect(() => {
    getCurrentApplication();
  }, []);

  return (
    <form>
      <>
        <Styled.Text>Dodaj swoją pracę poniżej</Styled.Text>
        <FormItem>
          <FileUploaderDropContainer
            labelText="Przeciągnij pliki lub kliknij, aby dodać"
            multiple
            name=""
            onAddFiles={(e) => {
              handleFile(e);
            }}
            role=""
          />
          {isApplicationAlreadyUploaded ? (
            <Styled.Text>
              Twoja praca została dodana, ale możesz jeszcze możesz ją zmienić -
              aby to zrobić, dodaj kolejny plik
            </Styled.Text>
          ) : (
            <Styled.Text>
              Jeszcze nie dodałeś żadnej pracy - aby to zrobić, dodaj plik.
            </Styled.Text>
          )}
          {uploadingStatus === "uploading" ? (
            <FileUploaderItem name={file?.name} status={uploadingStatus} />
          ) : (
            ""
          )}
          {uploadingStatus === "complete" ? (
            <>
              <FileUploaderItem name={file?.name} status={uploadingStatus} />
              <p>Dodałeś plik z pracą!</p>
            </>
          ) : (
            ""
          )}
        </FormItem>
        {isCheckboxVisible && (
          <Checkbox
            id={"praca"}
            labelText={
              "Świadom odpowiedzialności oświadczam, że praca etapu szkolnego Olimpiady Filozoficznej\n" +
              "została napisana przeze mnie samodzielnie i nie zawiera treści uzyskanych w sposób niezgodny z\n" +
              "obowiązującymi przepisami. Oświadczam również, że przedstawiona praca nie była wcześniej\n" +
              "publikowana i nie była przedmiotem innych konkursów przedmiotowych."
            }
            onChange={() => {
              setIsChecked(true);
            }}
          />
        )}
      </>
    </form>
  );
};

export default FileUploader;
