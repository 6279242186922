import React, { FunctionComponent, useState } from "react";
import * as Styled from "../../RegisterScreen.styled";
import InputText from "../../../../components/InputText/InputText";
import {
  Button,
  Checkbox,
  Loading,
  Select,
  SelectItem,
} from "carbon-components-react";
import { District } from "../../../../types/District";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../../../services/api";
import Student from "../../../../types/Student";
import { DistrictsContext } from "../../../../context/DistrictsContext/DistrictsContext";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import SuccessScreen from "../SuccessScreen/SuccessScreen";
import useCheckAge from "../../../../hooks/useCheckAge";
import CustomModal from "../../../../components/CustomModal/CustomModal";

type Props = {};
type Inputs = Student;

const RegisterForm: FunctionComponent<Props> = () => {
  const {
    session: { districts },
  } = React.useContext(DistrictsContext);

  const [success, setSuccess] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [userBirthDate, setUserBirthDate] = useState<string | null>(null);
  const isUserLessThen16: boolean = useCheckAge(userBirthDate);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoadingState(true);
    try {
      await api.post("/public/register-student ", {
        ...data,
      });
      setSuccess(true);
      setLoadingState(false);
    } catch (err) {
      if (err) {
        setErrMsg(
          "Błąd połączenia. Jeżeli użyłeś już tego maila do rejestracji, użyj opcji 'zapomniałem hasła'"
        );
        setLoadingState(false);
      }
    }
  };

  return (
    <>
      {loadingState && (
        <CustomModal>
          <Loading />
        </CustomModal>
      )}
      {success ? (
        <SuccessScreen />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Styled.SubHeader>Twoje dane</Styled.SubHeader>
          <InputText
            register={register("firstName", {
              required: true,
            })}
            helperText={"Wprowadź imię"}
            id={"firstname"}
            labelText={"Imię"}
            placeholder={"Jan"}
            disabled={false}
            invalid={false}
            errors={
              errors.firstName && (
                <Styled.ErrorMessage>Wprowadź imię</Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("lastName", {
              required: true,
            })}
            helperText={"Wprowadź nazwisko"}
            id={"lastname"}
            labelText={"Nazwisko"}
            placeholder={"Kowalski"}
            disabled={false}
            invalid={false}
            errors={
              errors.lastName && (
                <Styled.ErrorMessage>Wprowadź nazwisko</Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("email", {
              required: true,
              pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
            })}
            helperText={"Wprowadź adres e-mail"}
            id={"email"}
            labelText={"E-mail"}
            placeholder={"jan@gmail.com"}
            disabled={false}
            invalid={false}
            errors={
              errors.email && (
                <Styled.ErrorMessage>
                  Wprowadź poprawny adres e-mail
                </Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("password", {
              required: true,
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
            })}
            helperText={"Wprowadź hasło"}
            id={"password"}
            type={"password"}
            labelText={"Hasło"}
            placeholder={""}
            disabled={false}
            invalid={false}
            errors={
              errors.password && (
                <Styled.ErrorMessage>
                  Wprowadź hasło w formcie: co najmniej jedna mała litera, jedna
                  duża i jedna cyfra, min. 6 znaków, maks. 20 znaków
                </Styled.ErrorMessage>
              )
            }
            //todo: zrobic potwierdzenie hasła
          />
          <InputText
            register={register("phoneNumber", {
              required: true,
            })}
            helperText={"Wprowadź numer telefonu"}
            id={"phone"}
            labelText={"Numer telefonu"}
            placeholder={"123 456 789"}
            disabled={false}
            invalid={false}
            errors={
              errors.phoneNumber && (
                <Styled.ErrorMessage>
                  Wprowadź numer telefonu
                </Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("fathersFirstName", {
              required: true,
            })}
            helperText={"Wprowadź imię ojca"}
            id={"fathersfirstname"}
            labelText={"Imię ojca"}
            placeholder={"Mariusz"}
            disabled={false}
            invalid={false}
            errors={
              errors.fathersFirstName && (
                <Styled.ErrorMessage>Wprowadź imię ojca</Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("birthCity", {
              required: true,
            })}
            helperText={"Wprowadź miejsce urodzenia"}
            id={"birthcity"}
            labelText={"Miejsce urodzenia"}
            placeholder={"Łódź"}
            disabled={false}
            invalid={false}
            errors={
              errors.birthCity && (
                <Styled.ErrorMessage>
                  Wprowadź miejsce urodzenia
                </Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("birthDate", {
              required: true,
              validate: {
                positive: (value) => new Date(value) < new Date(),
              },
              onChange: (e) => {
                setUserBirthDate(e.target.value);
              },
            })}
            type={"date"}
            helperText={"Wprowadź datę urodzenia"}
            labelText={"Data urodzenia"}
            id={"dateofbirth"}
            placeholder={""}
            disabled={false}
            invalid={false}
            errors={
              errors.birthDate && (
                <Styled.ErrorMessage>
                  Wprowadź poprawną datę
                </Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("teachersName", {
              required: false,
            })}
            helperText={"Wprowadź imię i nazwisko nauczyciela"}
            id={"techersname"}
            labelText={"Imię i nazwisko nauczyciela (opcjonalnie)"}
            placeholder={"Jan Kowalski"}
            disabled={false}
            invalid={false}
          />
          <InputText
            register={register("patronName", {
              required: false,
            })}
            helperText={"Wprowadź imię i nazwisko opiekuna"}
            id={"patrons name"}
            labelText={"Imię i nazwisko opiekuna (opcjonalnie)"}
            placeholder={"Jan Kowalski"}
            disabled={false}
            invalid={false}
          />
          <Styled.SubHeader>Adres</Styled.SubHeader>
          <InputText
            register={register("address.street", {
              required: true,
            })}
            helperText={"Wprowadź ulicę i numer"}
            id={"street"}
            labelText={"Ulica i numer"}
            placeholder={"ul. Nowogrodzka 12 m. 34"}
            disabled={false}
            invalid={false}
            errors={
              errors.address?.street && (
                <Styled.ErrorMessage>Wprowadź ulicę</Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("address.city", {
              required: true,
            })}
            helperText={"Wprowadź miasto"}
            id={"city"}
            labelText={"Miasto"}
            placeholder={"Skierniewice"}
            disabled={false}
            invalid={false}
            errors={
              errors.address?.city && (
                <Styled.ErrorMessage>Wprowadź miasto</Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("county", {
              required: true,
            })}
            helperText={"Wprowadź powiat"}
            id={"county"}
            labelText={"Powiat"}
            placeholder={"łowicki"}
            disabled={false}
            invalid={false}
            errors={
              errors.county && (
                <Styled.ErrorMessage>Wprowadź powiat</Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("address.postalCode", {
              required: true,
              pattern: /^[0-9]{2}-[0-9]{3}$/,
            })}
            helperText={"Wprowadź kod pocztowy"}
            id={"postal-code"}
            labelText={"Kod pocztowy"}
            placeholder={"99-489"}
            disabled={false}
            invalid={false}
            errors={
              errors.address?.postalCode && (
                <Styled.ErrorMessage>
                  Wprowadź kod pocztowy w formacie 00-000
                </Styled.ErrorMessage>
              )
            }
          />
          <Select
            id={"district"}
            {...register("district")}
            labelText={"Okręg"}
            helperText={`Jeżeli nie wiesz, do którego okręgu należysz, sprawdź poniższy link`}
          >
            {districts?.map((d: District) => (
              <SelectItem text={d.name} value={d["@id"]} key={d["@id"]} />
            ))}
          </Select>
          <a
            href={"https://ptfilozofia.pl/program-2/"}
            target={"_blank"}
            style={{ textDecoration: "underline", color: "blue" }}
            rel="noreferrer"
          >
            Tutaj
          </a>
          <Styled.SubHeader style={{ marginTop: "3rem" }}>
            Dane szkoły
          </Styled.SubHeader>
          <InputText
            register={register("school.name", {
              required: true,
            })}
            helperText={"Wprowadź nazwę szkoły"}
            id={"schoolname"}
            labelText={"Nazwa szkoły"}
            placeholder={"LO w Łowiczu"}
            disabled={false}
            invalid={false}
            errors={
              errors.school?.name && (
                <Styled.ErrorMessage>Wprowadź nazwę szkoły</Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("school.address.city", {
              required: true,
            })}
            helperText={"Wprowdź miasto"}
            id={"schoolcity"}
            labelText={"Miasto"}
            placeholder={"Łowicz"}
            disabled={false}
            invalid={false}
            errors={
              errors.school?.address?.city && (
                <Styled.ErrorMessage>
                  Wprowadź miasto szkoły
                </Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("school.address.street", {
              required: true,
            })}
            helperText={"Wprowadź ulicę i numer szkoły"}
            id={"adressstreet"}
            labelText={"Ulica"}
            placeholder={"ul. Cicha 24"}
            disabled={false}
            invalid={false}
            errors={
              errors.school?.address?.street && (
                <Styled.ErrorMessage>
                  Wprowadź ulicę i numer szkoły
                </Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("school.address.postalCode", {
              required: true,
              pattern: /^[0-9]{2}-[0-9]{3}$/,
            })}
            helperText={"Wprowadź kod pocztowy szkoły"}
            id={"schoolpostalcode"}
            labelText={"Kod pocztowy szkoły"}
            placeholder={"99-418"}
            disabled={false}
            invalid={false}
            errors={
              errors.school?.address?.postalCode && (
                <Styled.ErrorMessage>
                  Wprowadź kod pocztowy szkoły w formacie 00-000
                </Styled.ErrorMessage>
              )
            }
          />
          <InputText
            register={register("school.class", {
              required: true,
            })}
            helperText={"Wprowadź numer klasy"}
            id={"schoolclass"}
            labelText={"Numer klasy"}
            placeholder={"III"}
            disabled={false}
            invalid={false}
            errors={
              errors.school?.class && (
                <Styled.ErrorMessage>Wprowadź numer klasy</Styled.ErrorMessage>
              )
            }
          />
          <Checkbox
            id={"zgoda1"}
            labelText={
              "Oświadczam, że powyższe dane są zgodne ze stanem faktycznym."
            }
            required={true}
          />
          <Checkbox
            id={"zgoda3"}
            labelText={
              "Oświadczam, że zapoznałem/am się z Klauzulą informacyjną o przetwarzaniu danych osobowych."
            }
            required={true}
          />
          <br></br>
          {isUserLessThen16 && (
            <Checkbox
              id={"zgoda2"}
              labelText={
                "Jako opiekun prawny oświadczam, że powyższe dane są zgodne ze stanem faktycznym."
              }
              required={true}
            />
          )}

          <Button type={"submit"}>Zarejestruj</Button>
          {errMsg && <Styled.ErrorMessage>{errMsg}</Styled.ErrorMessage>}

          <Styled.Text>
            XXXV OLIMPIADA FILOZOFICZNA KLAUZULA INFORMACYJNA O PRZETWARZANIU
            DANYCH OSOBOWYCH Na podstawie art. 13 ust.1 i 2 Rozporządzenia
            Parlamentu Europejskiego i Rady (UE) 2016/678 z dnia 27 kwietnia
            2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
            danych osobowych i w sprawie swobodnego przepływu tych danych oraz
            uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych osobowych) – zwanego dalej RODO, informujemy, iż : 1)
            Administratorem Pani/Pana danych osobowych jest: Polskie Towarzystwo
            Filozoficzne z siedzibą w Warszawie (ul. Nowy Świat 72 p.160, 00-330
            Warszawa) 2) Administrator danych osobowych – Polskie Towarzystwo
            Filozoficzne - przetwarza Pani/Pana dane osobowe na podstawie art. 6
            ust. 1 lit a. RODO oraz Rozporządzenia Ministra Edukacji Narodowej i
            Sportu z dnia 29 stycznia 2002 r. w sprawie organizacji oraz sposobu
            przeprowadzania konkursów, turniejów i olimpiad. 3) Pani/Pana dane
            osobowe przetwarzane będą w celu/celach: a) realizacji zadań
            związanych z przeprowadzeniem przedmiotowej olimpiady szkolnej
            „Olimpiada Filozoficzna”. Podstawą prawną, zgodnie z którą
            realizowana jest Olimpiada Filozoficzna, jest Rozporządzenie
            Ministra Edukacji Narodowej i Sportu z dnia 29 stycznia 2002 r. w
            sprawie organizacji oraz sposobu przeprowadzania konkursów,
            turniejów i olimpiad (Dz. U. z 2002 r., nr 13, poz. 125, z późn.
            zm.). 4) W związku z przetwarzaniem danych w celach o których mowa w
            pkt 3 odbiorcą Pani/Pana danych osobowych będą instytucje i urzędy
            wskazane w tym Rozporządzeniu oraz po uzyskaniu Pani/Pana zgody
            instytucje współpracujące z Polskim Towarzystwem Filozoficznym i KG
            OF w celu propagowania Olimpiady Filozoficznej. 5) Pani/Pana dane
            osobowe będą przechowywane do czasu ustawowo określonego w
            Rozporządzeniu Ministra Edukacji Narodowej i Sportu z dnia 29
            stycznia 2002 r. w sprawie organizacji oraz sposobu przeprowadzania
            konkursów, turniejów i olimpiad (Dz. U. z 2002 r., nr 13, poz. 125,
            z późn. zm.). 6) W związku z przetwarzaniem danych osobowych
            pełnoletniemu uczestnikowi Olimpiady oraz rodzicom lub prawnym
            opiekunom uczestnika nieletniego przysługuje prawo dostępu do treści
            danych uczestnika, do ich sprostowania, usunięcia, ograniczenia ich
            przetwarzania i przenoszenia, a także prawo do wniesienia sprzeciwu,
            oraz inne uprawnienia w tym zakresie wynikające z obowiązujących
            przepisów prawa. 7) W przypadku gdy przetwarzanie danych osobowych
            odbywa się na podstawie art. 6 ust. 1 lit a) RODO, czyli zgody na
            przetwarzanie danych osobowych, pełnoletniemu uczestnikowi Olimpiady
            oraz rodzicom lub prawnym opiekunom uczestnika nieletniego
            przysługuje prawo do cofnięcia tej zgody w dowolnym momencie, bez
            wpływu na zgodność przetwarzania, którego dokonano na podstawie
            zgody przed jej cofnięciem, z obowiązującym prawem. 8) W przypadku
            powzięcia informacji o niezgodnym z prawem przetwarzaniu danych
            osobowych w Polskim Towarzystwie Filozoficznym przysługuje Pani/Panu
            prawo wniesienia skargi do organu nadzorczego właściwego w sprawach
            ochrony danych osobowych. 9) Podanie przez Panią/Pana danych
            osobowych ma charakter dobrowolny, ale jest warunkiem uczestnictwa w
            Olimpiadzie Filozoficznej. 10) Pani/Pana dane nie będą przetwarzane
            w sposób zautomatyzowany i nie będą profilowane.
          </Styled.Text>
        </form>
      )}
    </>
  );
};

export default RegisterForm;
