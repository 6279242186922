import {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
} from "styled-components";
import ThemeStyle from "./ThemeStyles";

export const mediaUp = (
  media: string,
  children: string | FlattenSimpleInterpolation | FlattenInterpolation<any>
) => css`
  @media only screen and (min-width: ${media}) {
    ${children}
  }
`;

export const mediaDown = (
  media: string,
  children: string | FlattenSimpleInterpolation | FlattenInterpolation<any>
) => css`
  @media only screen and (max-width: ${media}) {
    ${children}
  }
`;

export const Section = css`
  width: 100%;
`;

export const Container = css`
  height: 100%;
  box-sizing: border-box;
  max-width: ${ThemeStyle.maxWidth};
  margin: 0 2.1rem;
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin: 0 10rem;
    `
  )}
  ${mediaUp(
    ThemeStyle.breakpoints.md,
    css`
      margin: 0 10rem;
    `
  )}
  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      margin: 0 auto;
    `
  )}
`;

export const Transition = (
  properties: string = "all",
  time: number = 0.4
) => css`
  transition: ${properties} ${time}s;
  transition-timing-function: ease-in-out;
`;

export const MobileHidden = css`
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      display: none;
    `
  )}
`;
export const DesktopHidden = css`
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: none;
    `
  )}
`;
