import styled from "styled-components";
import ThemeStyles from "../../../../styles/ThemeStyles";
import {
  BaseContainer,
  BasicDescending,
  HeaderH2,
  Section,
} from "../../../../styles/BasicStyles";

export const FirstLevelSection = styled(Section)`
  background-color: ${ThemeStyles.colors.sections.firstLevelSection};

  .cds--file__drop-container {
    font-size: 1rem;
  }
`;

export const Container = styled.div`
  ${BaseContainer};
`;

export const Title = styled.h2`
  ${HeaderH2};
  font-weight: bold;
`;

export const Descending = styled.p`
  ${BasicDescending}
`;
