// @ts-nocheck
import React, { FunctionComponent } from "react";
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandedRow,
  TableExpandHeader,
  TableExpandRow,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from "carbon-components-react";
import Student from "../../types/Student";
import ExpandedRow, { Methods } from "../ExpandedRow/ExpandedRow";
import { Application } from "../../types/Application";
import { DistrictsContext } from "../../context/DistrictsContext/DistrictsContext";
import * as Styled from "./StudentsTable.styled";
import api from "../../services/api";
import { OptionsContext } from "../../context/OptionsContext/OptionsContext";
import { OptionsType } from "../../types/Options";
import StudentStatusStageOne from "../StudentStatusStageOne";

const headers = [
  {
    key: "firstName",
    header: "Imię",
  },
  {
    key: "lastName",
    header: "Nazwisko",
  },
  {
    key: "school",
    header: "Szkoła",
  },
  {
    key: "district",
    header: "Okręg",
  },
  {
    key: "",
    header: "Etap I",
  },
  {
    key: "",
    header: "Etap II",
  },
];

type Props = {
  students: Student[];
  applications?: Application[] | null;
  options?: OptionsType | null;
  title: string;
  description: string;
  rowsButtonMethod?: Methods;
};

type DataTableProps = {
  rows: Student[];
  headers: [{ header: string }];
  getTableProps: () => {};
  getHeaderProps: ({ header }: any) => {};
  getRowProps: ({ header }: any) => {};
};

const StudentsTable: FunctionComponent<Props> = ({
  students,
  title,
  description,
  applications,
}) => {
  const {
    session: { districts },
  } = React.useContext(DistrictsContext);
  const {
    session: { options },
  } = React.useContext(OptionsContext);

  let dist: string;

  const getStudentApplication = (
    student: Student,
    applications: Application[] | undefined | null
  ): Application | undefined => {
    const appli = applications?.filter((a: Application) => {
      return a.student["@id"].replace("/api/students/", "") === student.id;
    });
    if (!appli) {
      return;
    }
    return appli[0];
  };

  const getStudentStatusStageII = (student: Student) => {
    return (
      <Styled.CirclesWrapper>
        <Styled.CircleIndicator
          isActive={
            student.application &&
            !!student.application.gradeSecondStage?.firstGrade
          }
        />
        <Styled.CircleIndicator
          isActive={
            student.application &&
            !!student.application.gradeSecondStage?.secondGrade
          }
        />
        <Styled.CircleIndicator
          isActive={
            student.application &&
            !!student.application.gradeSecondStage?.thirdGrade
          }
        />
      </Styled.CirclesWrapper>
    );
  };

  const downloadXls = () => {
    api.get("/students/export").then((response) => {
      const content = response.data;

      const element = document.createElement("a");
      const file = new Blob([content], { type: "text/csv" });
      element.href = URL.createObjectURL(file);
      element.download = "exported.csv";
      document.body.appendChild(element);
      element.click();
    });
  };

  return (
    <DataTable rows={students} headers={headers} isSortable={false}>
      {({
        rows,
        headers,
        getTableProps,
        getHeaderProps,
        getRowProps,
      }: DataTableProps) => (
        <TableContainer title={title} description={description}>
          <TableToolbar>
            <TableToolbarContent>
              <Button onClick={downloadXls}>Pobierz dane </Button>
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                <TableExpandHeader />

                {headers.map((header) => (
                  <TableHeader {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <React.Fragment key={row.id}>
                  {districts && (
                    <TableExpandRow {...getRowProps({ row })} key={row.id}>
                      {districts.filter((d) => {
                        if (d["@id"] === row.cells[3].value["@id"]) {
                          dist = d.name;
                        }
                      })}
                      {row.cells.map((cell: any, idy: number) => (
                        <TableCell key={cell.id}>
                          {idy === 2 ? (
                            cell.value.name
                          ) : idy === 3 ? (
                            dist
                          ) : idy === 4 ? (
                            <div>
                              {options && (
                                <StudentStatusStageOne
                                  student={students[idx]}
                                  application={getStudentApplication(
                                    students[idx],
                                    applications
                                  )}
                                  options={options}
                                />
                              )}
                            </div>
                          ) : idy === 5 ? (
                            <div>{getStudentStatusStageII(students[idx])}</div>
                          ) : (
                            cell.value
                          )}
                        </TableCell>
                      ))}
                    </TableExpandRow>
                  )}
                  <TableExpandedRow
                    colSpan={headers.length + 1}
                    key={students[idx].id}
                  >
                    {students[idx] && (
                      <ExpandedRow
                        key={students[idx].id}
                        student={students[idx]}
                        application={getStudentApplication(
                          students[idx],
                          applications
                        )}
                      />
                    )}
                  </TableExpandedRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};

export default StudentsTable;
