import React, { FunctionComponent, useEffect } from "react";
import * as Styled from "./AdminPage.styled";
import AcceptedStudents from "../../components/AcceptedStudents/AcceptedStudents";
import PendingStudents from "../../components/PendingStudents/PendingStudents";
import Districts from "./screens/Disctricts/Districts";
import Managing from "./screens/Managing/Managing";
import Table from "../../components/Table/Table";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { OptionsContext } from "../../context/OptionsContext/OptionsContext";

type Props = {};

const AdminPage: FunctionComponent<Props> = () => {
  const adminTabs: string[] = [
    "Zaakceptowani uczniowie",
    "Zgłoszenia",
    "Okręgi",
    "Zarządzanie",
  ];

  const {
    session: { options },
    loadOptions,
  } = React.useContext(OptionsContext);

  const adminTabsPanels: JSX.Element[] = [
    <AcceptedStudents />,
    <PendingStudents />,
    <Districts />,
    <Managing options={options} />,
  ];

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <Styled.AdminPage>
      <Styled.Container>
        <Table tabs={adminTabs} tabsPanels={adminTabsPanels} />
        <LogoutButton />
      </Styled.Container>
    </Styled.AdminPage>
  );
};

export default AdminPage;
