import styled, { css } from "styled-components";
import { BaseContainer, Section } from "../../styles/BasicStyles";

export const AdminPage = styled(Section)`
  height: 300vh;
  background-color: #121619;
`;
export const Container = styled.div`
  ${BaseContainer};
`;
