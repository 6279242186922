import React, { FunctionComponent, useMemo } from "react";
import Student from "../types/Student";
import { Application } from "../types/Application";
import * as Styled from "./StudentsTable/StudentsTable.styled";
import { OptionsType } from "../types/Options";

type Props = {
  student: Student;
  application: Application | undefined;
  options: OptionsType;
};

const StudentStatusStageOne: FunctionComponent<Props> = ({
  student,
  application,
  options,
}) => {
  const studentStatus: { studentState: string; color: string } = useMemo(() => {
    console.log(new Date() < new Date(options[0].value));
    console.log(options);
    if (student.state === "pending") {
      return {
        studentState: "Oczekujący",
        color: "#ffb784",
      };
    }
    if (
      !application ||
      (application &&
        application.files.length === 0 &&
        new Date() < new Date(options[0].value))
    ) {
      return {
        studentState: "Może wgrać pracę",
        color: "#F1C21B",
      };
    }
    if (
      !application ||
      (application &&
        application.files.length === 0 &&
        new Date() >= new Date(options[0].value))
    ) {
      return {
        studentState: "Nie wgrał pracy",
        color: "#8c8c8c",
      };
    }
    if (
      application &&
      application.files.length > 0 &&
      (application.gradeFirstStage === undefined ||
        application.gradeFirstStage === null)
    ) {
      return {
        studentState: "Oczekuje na ocenę",
        color: "#d4bbff",
      };
    }
    if (application.gradeFirstStage || application.gradeFirstStage === 0) {
      return {
        studentState: "Oceniono",
        color: "#6fdc8c",
      };
    }
    return {
      studentState: "Status nieznany",
      color: "#8c8c8c",
    };
  }, [student, application, options]);

  return (
    <Styled.ColorLabelWrapper>
      <Styled.ColorLabel color={studentStatus.color}>
        <Styled.LabelTitle>{studentStatus.studentState}</Styled.LabelTitle>
      </Styled.ColorLabel>
    </Styled.ColorLabelWrapper>
  );
};

export default StudentStatusStageOne;
