import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./FirstLevelSection.styled";
import Notification from "../../../../components/Notification/Notification";
import { AuthContext } from "../../../../context/AuthContext";
import api from "../../../../services/api";
import FileUploader from "./components/FileUploader";
import ProgressBar from "./components/ProgressBar";
type Props = {};

const FirstLevelSection: FunctionComponent<Props> = (props) => {
  const {
    session: { user },
  } = React.useContext(AuthContext);

  const [dateOption, setDateOption] = useState<{ value: string } | undefined>();
  const [grade, setGrade] = useState();
  const [gradesOption, setGradesOption] = useState<
    { value: boolean } | undefined
  >();
  const [isWinner, setIsWinner] = useState<boolean>(false);

  const getDateOption = () => {
    api.get("/options").then((r) => {
      setDateOption(r.data["hydra:member"][0]);
    });
  };

  const getApplications = () => {
    api.get("/applications").then((r) => {
      setGrade(
        r.data["hydra:member"].length > 0
          ? r.data["hydra:member"][0].gradeFirstStage
          : undefined
      );
    });
  };

  const getGradesOption = () => {
    api.get("/options").then((r) => {
      setGradesOption(
        r.data["hydra:member"].length > 0
          ? r.data["hydra:member"][1]
          : undefined
      );
    });
  };

  let currentDate = new Date();

  const getOptionsDate = (options: any) => {
    return new Date(options.value);
  };

  useEffect(() => {
    getDateOption();
    getGradesOption();
    getApplications();
  }, []);

  useEffect(() => {
    setIsWinner(Number(grade) >= 20);
  }, [grade]);

  return (
    <Styled.FirstLevelSection>
      <Styled.Container>
        <Styled.Title>ETAP I</Styled.Title>
        <Styled.Descending>
          Swoją pracę możesz wgrać do:{" "}
          <p style={{ fontWeight: "bold", display: "inline-block" }}>
            {dateOption
              ? dateOption.value.replace("T00:00:00+00:00", "")
              : "nie podano"}
          </p>
        </Styled.Descending>
        {user?.roles.includes("ROLE_STUDENT_ACCEPTED") ? (
          dateOption ? (
            getOptionsDate(dateOption) > currentDate ? (
              <FileUploader />
            ) : gradesOption?.value &&
              (grade !== null || grade !== undefined) ? (
              <ProgressBar
                value={grade}
                isWinner={isWinner}
                maxValue={"30"}
                title={"Twój wynik pracy etapu I to:"}
                descending={
                  isWinner
                    ? "Gratulujemy! Przechodzisz do następnego etapu olimpiady!"
                    : "Przykro nam, ale nie uzyskałeś wystarczającej liczby punktów, aby przejść do następnego etapu" +
                      " olimpiady. Wymagana liczba punktów to minimum 20 "
                }
              />
            ) : (
              <Notification
                kind={"warning"}
                title={
                  " Niesety, czas na wgrywanie prac minął. Jeżeli wgrałeś swoją\n" +
                  "                pracę, oczekuj maila z informacją o ocenie. Jeżeli dostałeś/aś już maila, a w tym" +
                  " miejscu nie ma twojej oceny, skontaktuj się z komietem."
                }
              />
            )
          ) : (
            ""
          )
        ) : (
          <Notification
            kind={"warning"}
            title={
              "Panel zostanie odblokowany po zaakceptowaniu Twojego zgłoszenia przez Komitet Okręgowy"
            }
          />
        )}
      </Styled.Container>
    </Styled.FirstLevelSection>
  );
};

export default FirstLevelSection;
