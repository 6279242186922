import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./Managing.styled";
import api from "../../../../services/api";
import InputText from "../../../../components/InputText/InputText";
import { Button, Loading } from "carbon-components-react";
import { SubmitHandler, useForm } from "react-hook-form";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import StageI from "./Stage I/StageI";
import StageII from "./Stage II /StageII";
import StageIModal from "./Stage I/StageIModal";
import StageIIModal from "./Stage II /StageIIModal";
import StageIII from "./StageIII/StageIII";
import StageIIIModal from "./StageIII/StageIIIModal";
import { OptionsType } from "../../../../types/Options";

type Props = {
  options: OptionsType | null;
};
type Inputs = {
  value: string;
};
const Managing: FunctionComponent<Props> = ({ options }) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isFirstStageModalOpen, setIsFirstStageModalOpen] =
    useState<boolean>(false);
  const [isSecondStageModalOpen, setIsSecondStageModalOpen] =
    useState<boolean>(false);

  const [isThirdStageModalOpen, setIsThirdStageModalOpen] =
    useState<boolean>(false);

  const { register, handleSubmit } = useForm<Inputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const changeDate: SubmitHandler<Inputs> = async (data) => {
    setSuccess(false);
    setLoadingState(true);
    try {
      await api.post("/options/stage-one/change-date", {
        value: data.value,
      });
      setLoadingState(false);
      setSuccess(true);
      setIsFirstStageModalOpen(false);
    } catch (err) {
      if (err) {
        //todo: czy obsłużyć inne błędy i jak? typu: taki adres mail już istnije
      }
    }
  };

  const publishFirstStageGrades = async () => {
    setSuccess(false);
    setLoadingState(true);
    try {
      await api.post("/options/stage-one/publish-grades");
      setLoadingState(false);
      setSuccess(true);
      setIsFirstStageModalOpen(false);
    } catch (err) {
      if (err) {
      }
    }
  };

  const publishSecondStageGrades = async () => {
    setSuccess(false);
    setLoadingState(true);
    try {
      await api.post("/options/stage-two/publish-grades");
      setLoadingState(false);
      setSuccess(true);
      setIsSecondStageModalOpen(false);
    } catch (err) {
      if (err) {
      }
    }
  };

  const publishThirdStageGrades = async () => {
    setSuccess(false);
    setLoadingState(true);
    try {
      await api.post("/options/stage-three/publish-grades");
      setLoadingState(false);
      setSuccess(true);
      setIsThirdStageModalOpen(false);
    } catch (err) {
      if (err) {
      }
    }
  };

  const onCloseFirstStageModal = () => {
    setIsFirstStageModalOpen(false);
  };
  const onCloseSecondStageModal = () => {
    setIsSecondStageModalOpen(false);
  };
  const onCloseThirdStageModal = () => {
    setIsThirdStageModalOpen(false);
  };
  return (
    <Styled.Managing>
      {isFirstStageModalOpen && (
        <StageIModal
          onCloseModal={onCloseFirstStageModal}
          publishGrades={publishFirstStageGrades}
        />
      )}
      {isSecondStageModalOpen && (
        <StageIIModal
          onCloseModal={onCloseSecondStageModal}
          publishGrades={publishSecondStageGrades}
        />
      )}
      {isThirdStageModalOpen && (
        <StageIIIModal
          onCloseModal={onCloseThirdStageModal}
          publishGrades={publishThirdStageGrades}
        />
      )}
      <StageIII
        options={options}
        openModalHandler={() => setIsSecondStageModalOpen(true)}
      />
      <StageII
        options={options}
        openModalHandler={() => setIsSecondStageModalOpen(true)}
      />
      <StageI
        options={options}
        onSubmitHandler={handleSubmit(changeDate)}
        register={register("value", {
          required: true,
        })}
        openModalHandler={() => setIsFirstStageModalOpen(true)}
      />
      {loadingState && (
        <CustomModal>
          <Loading />
        </CustomModal>
      )}
    </Styled.Managing>
  );
};

export default Managing;
