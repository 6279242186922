import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./WelcomeSection.styled";
import api from "../../../../services/api";
import Notification from "../../../../components/Notification/Notification";
import { Button, Modal } from "carbon-components-react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import Student from "../../../../types/Student";
import ChangeData from "./components/ChangeData";

type Props = {};

const WelcomeSection: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    session: { user },
  } = React.useContext(AuthContext);

  const [student, setStudent] = useState<Student>();

  const getStudent = () => {
    api.get("/students").then((r) => {
      setStudent(r.data["hydra:member"][0]);
    });
  };

  useEffect(() => {
    getStudent();
  }, []);

  const logout = () => {
    api.post("/logout").then(() => {
      navigate("/");
      window.location.reload();
    });
  };

  const changeData = () => {
    setIsModalOpen(true);
  };

  return (
    <Styled.WelcomeSection>
      <Styled.Container>
        {isModalOpen && (
          <Modal
            open
            modalHeading=""
            modalLabel="Edycja danych"
            primaryButtonText="Stwórz"
            secondaryButtonText="Anuluj"
            onRequestClose={() => {
              setIsModalOpen(false);
            }}
          >
            {student ? <ChangeData student={student} /> : ""}
          </Modal>
        )}
        <Styled.Greetings>Witaj {user?.name}!</Styled.Greetings>
        <Styled.Descending>
          Status Twojego zgłoszenia do Olimpiady Filozoficznej:{" "}
        </Styled.Descending>
        {user?.roles.includes("ROLE_STUDENT_ACCEPTED") ? (
          <Notification
            kind={"success"}
            title={"Twoje zgłoszenie zostało zaakceptowane"}
          />
        ) : (
          <Notification kind={"warning"} title={"Oczekujące"} />
        )}
        <Styled.ButtonWrapper>
          <Button onClick={logout}>Wyloguj</Button>
          <Button onClick={changeData}>Edytuj dane</Button>
        </Styled.ButtonWrapper>
      </Styled.Container>
    </Styled.WelcomeSection>
  );
};

export default WelcomeSection;
